import React from "react";
import cookie from "react-cookies";
import PropTypes from "prop-types";
import connect from "react-redux/lib/connect/connect";
import moment from "moment-timezone";
import { toast } from "react-toastify";

import * as UserDucks from "ducks/accounts/user";
import * as PaymentDucks from "ducks/vendors/payment";

import CollapseIconAtom from "atoms/VideoCollapseIcon";
import FooterSection from "sections/common/Footer";
import SidenavSection from "sections/common/Sidenav";
import SubHeaderSection from "sections/common/SubHeader";
import CollapsableInfoSection from "sections/common/CollapsableInfo";
import LayerAtom from "atoms/NewLayerModal";
import StorageGateway from "lib/storage-gateway";

import TrialPeriodNotification from "components/common/TrialPeriodNotification/trialPeriodNotification";
import Menu from "./ham-menu.png";

/**
 * TwoColumnLayout Layout
 *
 * Sections:
 *    - {@link SidenavSection}
 *    - {@link SubHeaderSection}
 *    - Content from children
 *    - {@link FooterSection}
 *
 * @example
 *  __________________________________
 * |            |                     |
 * |            |      SubHeader      |
 * |            |_____________________|
 * |            |                     |
 * |            |                     |
 * |  Sidenav   |       Content       |
 * |            |                     |
 * |            |_____________________|
 * |            |                     |
 * |            |       Footer        |
 * |____________|_____________________|
 *
 */

let company_id = "";
let nre_token = "";
let nre_company_id = "";
let userID = "";
class TwoColumnLayout extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      sidemenuCollapsed: false,
      toggleMailLayer: false,
    };
    this.toggleCollapse = this.toggleCollapse.bind(this);
    this.updateState = this.updateState.bind(this);
    this.showToast = this.showToast.bind(this);
    this.eventListnerFunction = this.eventListnerFunction.bind(this);
  }

  eventListnerFunction(event) {
    const data =
      event.data && typeof event.data !== "object" && JSON.parse(event.data);
    const message = data?.message;
    if (message === "CLOSE_NOTIFICATION_LAYER") {
      this.setState({ toggleMailLayer: false });
    }
  }

  componentWillMount() {
    if (cookie.load("sidemenuCollapsed") === "false") {
      this.setState({ sidemenuCollapsed: false });
      this.props.getNavStatus && this.props.getNavStatus(true);
    } else if (cookie.load("sidemenuCollapsed") === "true") {
      this.setState({ sidemenuCollapsed: true });
      this.props.getNavStatus && this.props.getNavStatus(false);
    }
    company_id = StorageGateway.get("company_id");
    nre_token = StorageGateway.get("graniteToken");
    nre_company_id = StorageGateway.get("nre_company_id");
    userID = StorageGateway.get("userId");
  }

  componentDidMount() {
    window.addEventListener("message", this.eventListnerFunction, false);
  }

  updateState(key, value) {
    this.setState({
      [key]: value,
    });
  }

  toggleCollapse() {
    this.setState({ sidemenuCollapsed: !this.state.sidemenuCollapsed });
    this.props.getNavStatus &&
      this.props.getNavStatus(this.state.sidemenuCollapsed);
  }

  showToast() {
    return toast.error(
      "Please finish the tutorial in order to add the card details. "
    );
  }
  componentWillUnmount() {
    window.removeEventListener("message", this.eventListnerFunction);
  }

  render() {
    const {
      notification,
      children,
      navInfo,
      infoList,
      toggleInfo,
      isInfoOpen,
      customerDetail,
      fetchData,
      toggle,
      isFinished,
      isBusinessAdmin,
      userProfile,
      userExtraData,
      jobPage,
      isLoading,
      isdashboard,
      editJob,
      jobDetail,
      hideContent,
      addPolicy,
      ptoBulkUpload,
      newDashboard,
    } = this.props;
    const { sidemenuCollapsed } = this.state;
    const tutorialCompleted =
      userProfile && userProfile.company?.tutorial_completed;
    const paddingValue = this.state.sidemenuCollapsed ? "55px" : "245px";
    const isEmployeeBool =
      userExtraData &&
      userExtraData.company_permissions &&
      userExtraData.company_permissions.is_employee;
    const isCompanyOwner =
      userExtraData &&
      userExtraData.company_permissions &&
      userExtraData.company_permissions.company_owner;
    cookie.save("sidemenuCollapsed", sidemenuCollapsed ? "true" : "false", {
      path: "/",
    });

    const showFlap =
      !isEmployeeBool &&
      isCompanyOwner &&
      Object.keys(customerDetail).length &&
      (customerDetail.commercial_status === "Trial Period" ||
        moment(customerDetail.trial_end) >= moment()) &&
      !isBusinessAdmin &&
      customerDetail.commercial_status !== "No Billing";
    const contanierHeight = showFlap ? "92vh" : "100vh";
    showFlap && cookie.save("isBannerFlag", "true", { path: "/" });
    return (
      <div className={showFlap ? "dashboard-mainbody" : null}>
        {showFlap ? (
          <div>
            <div className="pending">
              <TrialPeriodNotification
                customerDetail={customerDetail}
                tutorialDetail={tutorialCompleted}
                showToast={this.showToast}
                userExtraData={this.props.userExtraData}
              />
            </div>
          </div>
        ) : (
          <></>
        )}
        <div className={showFlap ? "mainbody" : "header"}>
          <div
            className={
              sidemenuCollapsed
                ? isBusinessAdmin
                  ? "business-collapsed-side-nav side-nav-common"
                  : "collapsed-side-nav side-nav-common"
                : isBusinessAdmin
                ? "business-side-nav side-nav-common"
                : "side-nav side-nav-common"
            }
            style={{
              width: paddingValue,
              zIndex: "11",
              minHeight: contanierHeight,
            }}
          >
            <div className={!isFinished ? null : "nav-wrapper-onboard"} />
            <div
              className="collapse-menu-icon"
              role="button"
              tabIndex="0"
              onClick={this.toggleCollapse}
            >
              <img src={Menu} height="14" width="16" alt="" />
            </div>
            {userExtraData && userExtraData.company_permissions && (
              <SidenavSection
                isFinished={isFinished}
                sidemenuCollapsed={sidemenuCollapsed}
                showFlap={showFlap}
              />
            )}
          </div>
          <div
            className="content container-padding"
            style={
              hideContent
                ? {
                    marginLeft: paddingValue,
                    transition: "margin-left .5s",
                    minHeight: contanierHeight,
                    display: "none",
                  }
                : {
                    marginLeft: paddingValue,
                    transition: "margin-left .5s",
                    minHeight: contanierHeight,
                  }
            }
          >
            {infoList && infoList.results && infoList.results.length ? (
              <div>
                <CollapsableInfoSection
                  infoList={infoList.results}
                  toggleInfo={toggleInfo}
                  isInfoOpen={isInfoOpen}
                />
                <div className="collapse-text pr-3">
                  <CollapseIconAtom
                    active={isInfoOpen}
                    className="cursor-pointer"
                    onClick={() => toggleInfo()}
                    id="collapseIcon"
                    height="1px"
                  />
                </div>
              </div>
            ) : null}
            {!isdashboard && (
              <SubHeaderSection
                notification={notification}
                toggle={toggle}
                navInfo={navInfo}
                jobPage={jobPage}
                editJob={editJob}
                fetchData={fetchData}
                isLoading={isLoading}
                jobDetail={jobDetail}
                createJob={this.props.createJob}
                goTo={this.props.goTo}
                createSurvey={this.props.createSurvey}
                addPolicy={addPolicy}
                ptoBulkUpload={ptoBulkUpload}
                updateState={this.updateState}
              />
            )}
            {children}
            <FooterSection newDashboard={newDashboard} />
          </div>
        </div>
        {this.state.toggleMailLayer ? (
          <LayerAtom
            active={true}
            heading={"Notification Settings"}
            path={`${
              process.env.REACT_APP_WMAPPER_BASE_URL
            }/notification-settings?access_token=${nre_token}&user_id=${userID}&companyId=${company_id ||
              nre_company_id}`}
            setToggle={() => this.setState({ toggleMailLayer: false })}
          />
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  userCompany: UserDucks.userCompany(state),
  isBusinessAdmin: UserDucks.isBusinessAdmin(state),
  customerDetail: PaymentDucks.customerDetail(state),
  userProfile: UserDucks.profile(state),
  userExtraData: UserDucks.userExtraData(state),
});

const mapActionsToProps = (dispatch) => ({});

TwoColumnLayout.propTypes = {
  children: PropTypes.node.isRequired,
  navInfo: PropTypes.object,
};

TwoColumnLayout.contextTypes = {
  router: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, mapActionsToProps)(TwoColumnLayout);
